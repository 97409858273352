@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
}

body {
  background-image: url(/images/background_8.jpg);
  background-color: #ffffff;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #212529;
  line-height: 150%;
}

@media (max-width: 959px) {
  body {
    font-size: 0.9rem;
  }
}

blockquote {
  margin: 20px 0 20px 35px;
  position: relative;
  font-size: 0.9rem;
  font-style: inherit;
  padding: 10px 5px 10px 15px;
  border-left: 5px solid #4400CC;
  background-color: #f8f8f8;
}

blockquote:before {
  content: "\201d";
  display: block;
  position: absolute;
  left: -35px;
  color: #4400CC;
  font-size: 60px;
  line-height: 70%;
}

.uk-alert {
  padding: 25px 29px 25px 25px;
}

.uk-alert-message {
  background: #edfbf6;
  color: #86e4c1;
}

a#toTop {
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: fixed;
  bottom: 15px;
  right: 15px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  font-size: 30px;
  background: linear-gradient(to top, #0072b3, #0092e6);
  justify-content: center;
  z-index: 9999;
  line-height: 180%;
}

a#toTop:hover {
  color: #7733ff;
}

.mt30 {
  margin-top: 30px;
}

.mt25 {
  margin-top: 25px;
}

.mt20 {
  margin-top: 20px;
}

.mt15 {
  margin-top: 15px;
}

.mt10 {
  margin-top: 10px;
}

.mt5 {
  margin-top: 5px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb5 {
  margin-bottom: 5px;
}

h1 {
  color: #0082CC;
}

.uk-section-default {
  background: inherit;
}

.cck-clear {
  display: none !important;
}

.uk-section.footer {
  padding-bottom: 0;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem;
  padding: 10px;
}

.popover h3 {
  padding: .5rem .75rem;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}

.popover-body {
  padding: .5rem .75rem;
  color: #212529;
}

.uk-flex {
  display: flex !important;
}

div.cck-f100 {
  display: inherit;
  position: inherit;
  width: auto;
}

.div-scroll-to-top {
  display: block;
  position: relative;
  width: 40px;
}

em {
  color: #212529;
}

.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Oranienbaum, serif;
}

p {
  text-align: justify;
}

#system-message {
  display: block;
  overflow: hidden;
  max-width: 1200px;
  margin: auto;
}

.first-page-list-a {
  display: block;
  overflow: hidden;
  position: relative;
}

.first-page-list-title {
  display: block;
  overflow: hidden;
  font-family: Oranienbaum, serif;
  margin-top: 10px;
  text-align: right;
  font-size: 1.7rem;
}

a .first-page-list-title {
  color: #1e87f0;
}

.slider-div {
  display: block;
  overflow: hidden;
}

.wave-top-svg {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 30px;
  position: absolute;
  top: 0;
}

.second-wave-svg.uk-svg {
  display: block;
  width: 100%;
  height: 30px;
  color: #ffffff;
}

.big-wave-svg-div {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  transform: rotate(180deg);
}

.big-wave-svg.uk-svg {
  display: block;
  overflow: hidden;
  width: 100%;
  color: #ffffff;
}

@media (max-width: 639px) {
  .big-wave-svg.uk-svg {
    height: 50px;
  }
}

@media (max-width: 959px) {
  .big-wave-svg.uk-svg {
    height: 75px;
  }
}

@media (min-width: 960px) {
  .big-wave-svg.uk-svg {
    height: 100px;
  }
}

.best-offers-a-link {
  display: table;
  overflow: hidden;
  color: #ffffff;
  font-weight: 400;
  background: #0082CC;
  border: 1px solid #00a3ff;
  margin: 20px auto;
  border-radius: 23px;
  padding: 10px 30px;
}

.best-offers-a-link:hover {
  color: #d9d9d9;
  background: #00a3ff;
}

.first-page-avatar {
  width: 100px;
  border-radius: 50%;
  float: left;
  margin-right: 30px;
  margin-bottom: 15px;
  border: 1px solid #d9d9d9;
}

.latest-contacts > a {
  font-size: .9rem;
  color: #212529;
}

.latest-contacts > a:hover {
  color: #4e5862;
  text-decoration: none;
}

.menu-block {
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainmenu {
  display: flex;
  padding-left: 0;
  justify-content: space-around;
  margin: 0;
}

.mainmenu li {
  display: inline-block;
  margin: 0;
  list-style: none;
  box-sizing: initial;
}

.mainmenu li a {
  display: block;
  overflow: hidden;
  font-family: Oranienbaum, serif;
  color: #b3b3b3;
  text-align: center;
  float: left;
  transition: all 0.3s;
  margin: 0 2px;
  padding: 0 7px 5px;
  border-bottom: 3px solid rgba(255, 255, 255, 0);
  height: 27px;
  box-sizing: initial;
}

.mainmenu li ul {
  display: none;
}

.mainmenu li.selected a, .mainmenu li.active a, .mainmenu li a:hover {
  color: #0082CC;
  border-bottom: 3px solid #0082CC;
  text-decoration: none;
}

@media (min-width: 640px) {
  .mainmenu li a {
    font-size: 13px;
    padding: 0 3px 2px;
  }
}

@media (min-width: 960px) {
  .mainmenu li a {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .mainmenu li a {
    font-size: 25px;
  }
}

ul.mainmenu li.active ul {
  display: block;
  height: 27px;
  position: absolute;
  top: 33px;
}

ul.mainmenu li.active ul li {
  display: inline;
}

ul.mainmenu li.active ul li a {
  border: 0;
  color: #C6C6C6;
  font-size: 18px;
  transition: all 1s;
  padding-top: 10px;
}

ul.mainmenu li.active ul li.active a,
ul.mainmenu li.active ul li a:hover,
ul.mainmenu li.active ul li.selected a {
  color: #ffffff;
  background-color: #00A221;
}

.logo {
  display: block;
  overflow: hidden;
}

nav {
  position: relative;
}

div.mod-languages ul {
  margin-right: 15px;
}

input[id="menuOnOff"] {
  display: none;
}

.uk-logo svg {
  height: 100%;
}

.uk-navbar-item {
  height: auto;
}

.header {
  background: #ffffff;
}

.top-icon-img {
  height: auto;
  color: #b3b3b3;
}

.top-icon-img:hover {
  color: #0082CC;
}

@media (max-width: 959px) {
  .top-icon-img {
    width: 32px;
  }
}

@media (min-width: 960px) {
  .top-icon-img {
    width: 40px;
  }
}

.top-menu {
  padding: 10px 0;
}

.menu-div {
  padding-top: 15px;
}

@media (max-width: 639px) {
  .menu-div {
    padding-top: 0;
  }
}

#menu_on_off, label[for="menu_on_off"] {
  display: none;
}

@media (max-width: 639px) {
  label[for="menu_on_off"] {
    display: flex;
    width: 45px;
    height: 45px;
    border: 2px solid #b3b3b3;
    border-radius: 5px;
    margin: auto;
    color: #b3b3b3;
    box-shadow: 0px 0px 5px #ffffff;
    cursor: pointer;
  }
  label[for="menu_on_off"] + ul {
    display: none;
    position: absolute;
    top: 70px;
    height: 0;
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    opacity: 0;
    background: rgba(255, 255, 255, 0);
    transition: all 1s linear;
  }
  label[for="menu_on_off"] > svg {
    width: 30px;
    height: 30px;
  }
  .menu-div > input:checked + label[for="menu_on_off"] + ul {
    display: table;
    background: white;
    height: auto;
    opacity: 1;
    transition: all 1s linear;
    z-index: 1000;
  }
  .mainmenu li {
    display: block;
    float: none;
    text-align: center;
  }
  .mainmenu li a {
    margin: 5px 2px;
    width: calc(100% - 8px);
    height: auto;
    padding: 0;
  }
}

input {
  padding: 4px 5px;
}

.edit-button-div {
  display: flex;
  justify-content: flex-end;
}

.edit-button-div > a {
  border: 1px solid #cccccc;
}

.control-group {
  display: flex;
  margin-bottom: 20px;
  align-items: stretch;
}

.control-group .control-label {
  width: calc(50% - 20px);
  margin-right: 20px;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
}

.control-group .control-label > label {
  margin: 0;
  display: table;
}

.controls {
  display: block;
  overflow: hidden;
}

.login .btn.btn-primary,
.remind .btn.btn-primary,
.reset .btn.btn-primary,
.logout .btn.btn-primary {
  display: table;
  margin: auto;
}

ul.login {
  display: table;
  width: auto;
  padding: 0;
  margin: auto;
}

ul.login li {
  list-style: none;
  margin: 0 10px 30px;
  float: left;
}

.cck_page_form label {
  display: block;
  overflow: hidden;
  float: left;
  width: calc(50% - 22px);
  text-align: right;
  margin-right: 20px;
  margin-bottom: 0;
  padding: 0.4rem 0;
}

.cck_page_form label + input,
.cck_page_form label + select,
.cck_page_form label + .field-calendar {
  width: 50%;
  margin-bottom: 20px;
}

.field-calendar {
  height: 40px;
  float: left;
}

.field-calendar .input-append > input {
  width: calc(100% - 38px);
  float: left;
}

.field-calendar .input-append > button {
  display: block;
  overflow: hidden;
  padding: 0;
  margin: 0;
  width: 38px;
  height: 38px;
  float: right;
}

.icon-calendar::after {
  content: "\2630";
  font-size: 20px;
}

div[id*="chars-article_intro_"] {
  display: block;
  overflow: hidden;
  clear: both;
  margin-bottom: 20px;
  text-align: center;
}

label[for*="art_fulltext"],
label[for*="art_metadesc"],
label[for="article_content_photo"],
label[for="article_photo_gallery"] {
  width: 100%;
  text-align: center;
  float: none;
  margin-bottom: 20px;
  margin-right: 0;
  font-size: 1.5rem;
}

label[for="article_intro_image_title"] {
  display: inline;
  font-size: inherit;
}

.tab-content {
  margin: 30px 0;
}

.btn-toolbar button {
  margin: 0 10px;
}

.logout {
  display: block;
  overflow: hidden;
  margin-bottom: 30px;
}

.free-text-title {
  font-size: 2.5rem;
  display: block;
  overflow: hidden;
  margin-bottom: 30px;
}

.free-text-title > label {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 8px 0;
}

.collection-group-button {
  display: flex;
  overflow: hidden;
  justify-content: right;
}

.icon-minus,
.icon-plus,
.icon-circle {
  display: block;
  width: 38px;
  height: 38px;
  float: left;
  margin-right: 10px;
  margin-bottom: 5px;
}

.icon-minus::after,
.icon-plus::after,
.icon-circle::after {
  content: "";
  font-size: 30px;
  font-weight: bold;
  border: 1px solid #cccccc;
  display: flex;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.icon-minus::after {
  content: "-";
}

.icon-plus::after {
  content: "+";
}

.icon-circle::after {
  content: "\00F7";
}

.icon-minus,
.icon-plus {
  cursor: pointer;
}

.icon-circle {
  cursor: all-scroll;
}

.adminformlist.ui-sortable > div {
  display: block;
  overflow: hidden;
  width: calc(50% - 10px);
  float: left;
  border-bottom: 1px solid #cccccc;
  padding: 15px 0;
}

.adminformlist.ui-sortable > div:nth-child(2n) {
  float: right;
}

.cck_label_upload_image,
.cck_form_upload_image {
  display: block;
  overflow: hidden;
  margin-bottom: 10px;
}

.cck_label_upload_image > label {
  width: 100%;
  text-align: left;
}

.photo-gallery-div .gallery-image {
  width: 25%;
}

.formError .formErrorContent {
  background: #ffffff;
  color: #212529;
  font-size: 13px;
  border: 1px solid #cccccc;
  box-shadow: 0 0 3px #cccccc;
}

.formError .formErrorArrow {
  border-color: #cccccc transparent transparent transparent;
}

#user_password_update > input {
  display: none;
}

#user_password_update > label {
  text-align: center;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 5px 10px;
  width: calc(50% - 30px);
  margin: 0;
}

#user_password_update > label:nth-child(4n) {
  float: right;
}

#user_password_update > input:checked + label {
  background: #dddddd;
}

.pass-change-div > div,
.pass-change-div > fieldset {
  margin-bottom: 20px;
}

div[id^="chars"] {
  display: table;
  margin: 0 auto 20px;
}

.user-add-edit-form > .hasTooltip {
  display: none;
}

.user-add-edit-form > .cck_upload_image {
  display: table;
  margin: auto;
}

ul.nav-tabs li {
  list-style: none;
  margin-left: 0;
}

fieldset {
  border: none;
}

fieldset[id^="moneybox_heading"] {
  border-bottom: 1px solid #0082CC;
}

.nav-tabs {
  display: flex;
  justify-content: flex-start;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(204, 204, 204, 0.8);
  padding-left: 0;
}

.nav-tabs > li {
  display: inline-table;
  padding: 7px 15px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(204, 204, 204, 0);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(204, 204, 204, 0);
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(204, 204, 204, 0);
}

.nav-tabs > li.active {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(204, 204, 204, 0.8);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(204, 204, 204, 0.8);
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(204, 204, 204, 0.8);
}

.tab-content > div {
  display: none;
}

.tab-content > div.active {
  display: block;
  overflow: hidden;
}

.cck_label.cck_label_password {
  display: inline-flex;
  width: calc(50% - 20px) !important;
  justify-content: flex-end;
  margin-right: 20px;
}

.cck_label.cck_label_password > label {
  width: auto;
  padding: 0;
  margin: .5rem 0 0;
}

.cck_forms.cck_site.cck_password {
  overflow: hidden;
}

.cck_form.cck_form_password {
  width: 50% !important;
}

.btn-toolbar {
  display: flex;
  justify-content: space-around;
}

fieldset[id="ekskursii_place"] input {
  display: none;
}

fieldset[id="ekskursii_place"] input:checked + label {
  background: #e6e6e6;
  border: 1px solid #cccccc;
}

fieldset[id="ekskursii_place"] label {
  text-align: center;
  border: 1px solid #e6e6e6;
  margin-bottom: 15px;
  cursor: pointer;
}

.uk-accordion-title {
  font-weight: 400;
  background: #0082CC;
  border: 1px solid #00a3ff;
  border-radius: 25px;
  color: #ffffff;
  padding: 10px 30px;
}

.uk-accordion-title:hover, .uk-accordion-title:focus {
  color: #e6e6e6;
}

.uk-accordion-title::after {
  background-image: none;
  content: "+";
}

.uk-open > .uk-accordion-title::after {
  background-image: none;
  content: "–";
}

.search-exkursion-module > label {
  display: flex;
  overflow: hidden;
  width: 30%;
  height: 40px;
  float: left;
  margin-right: 30px;
  margin-bottom: 15px;
  justify-content: flex-end;
  align-items: center;
  font-weight: 400;
}

.search-exkursion-module > select,
.search-exkursion-module > fieldset {
  display: block;
  overflow: hidden;
  width: calc(70% - 30px);
  margin-bottom: 15px;
}

.search-exkursion-module > fieldset {
  margin-top: 0;
}

.search-exkursion-module > fieldset > label {
  display: inline-table;
  overflow: hidden;
  margin: 3px 5px;
  background: #ffffff;
  padding: 5px 20px;
}

.share42init {
  display: flex;
  justify-content: center;
  margin: 40px 0 30px;
}

.share42-counter {
  background-image: none !important;
}

.share42-counter:before {
  background-image: none !important;
}

.article-header {
  display: block;
  position: relative;
  overflow: hidden;
}

.article-title {
  margin-bottom: 10px;
  text-align: right;
  color: #003300;
  padding: 10px 30px;
  margin-top: 30px;
  font-size: 2.5rem;
}

@media (max-width: 959px) {
  .article-title {
    font-size: 1.5rem;
  }
}

.intro-image {
  margin: 30px 0;
}

#cboxOverlay {
  background: #ffffff url(/images/background_8.jpg) center top;
}

.img-in-text {
  display: flex;
  justify-content: center;
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.img-in-text > a {
  display: flex;
  width: 100%;
  justify-content: center;
}

@media (max-width: 639px) {
  .img-in-text.right,
  .img-in-text.left {
    clear: both;
    margin: 20px auto 0;
  }
}

@media (min-width: 640px) {
  .img-in-text.right {
    float: right;
    margin: 10px 0 10px 20px;
  }
  .img-in-text.left {
    float: left;
    margin: 10px 20px 10px 0;
  }
}

.img-in-text.center {
  clear: both;
  margin: 20px auto;
}

.author-image {
  display: block;
  width: 10%;
  float: left;
}

.autor-id,
.author-name {
  text-align: right;
  font-weight: bold;
  padding-right: 30px;
}

.author {
  margin-top: 30px;
  border-top: 1px solid #00cf59;
  display: flex;
  overflow: hidden;
  padding: 20px 0;
  justify-content: space-around;
  align-items: center;
}

.author-image > img {
  border-radius: 50%;
  border: 1px solid #cccccc;
  padding: 4px;
}

.author td {
  color: #4d5961;
  font-size: 0.9rem;
}

.count-span {
  margin-left: 15px;
}

.count-span > span {
  margin-right: 5px !important;
}

.article_intro_photo {
  padding: 4px;
  border: 1px solid #e6e6e6;
}

@media (max-width: 959px) {
  .article_intro_photo {
    display: block;
    overflow: hidden;
    width: 100%;
    clear: both;
    margin: 20px 0;
  }
}

@media (min-width: 960px) {
  .article_intro_photo {
    display: block;
    overflow: hidden;
    width: 50%;
    float: right;
    margin: 20px 0 20px 20px;
  }
}

.photo-gallery {
  display: block;
  overflow: hidden;
}

.photo-gallery .photo-gallery-image {
  width: 33.33%;
  height: auto;
  padding: 4px;
  border: 1px solid #e6e6e6;
}

.article_author {
  display: block;
  overflow: hidden;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 20px 0;
  margin: 20px 0;
  clear: both;
}

.article_author img {
  float: left;
  margin: 0 30px;
  padding: 4px;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
}

.exskursion-parameters {
  display: block;
  overflow: hidden;
  font-size: 1.2rem;
  padding: .2rem 0;
}

.exskursion-parameters label {
  display: block;
  overflow: hidden;
  float: left;
  width: calc(50% - 20px);
  margin-right: 30px;
  text-align: right;
  font-weight: 400;
}

.exskursion-parameters > div {
  float: left;
  width: calc(50% - 10px);
}

.top_line {
  border-top: 1px solid #e6e6e6;
  padding-top: 1.5rem;
}

.bottom_line {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 1.5rem;
}

.pagination {
  display: block;
  overflow: hidden;
  clear: both;
}

.pagination ul {
  display: table;
  margin: auto;
  list-style: none;
}

.pagination ul li {
  display: inline-block;
  padding: 3px 10px;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
}

.share42init {
  display: block;
  position: absolute;
  left: 20px;
}

.div-blog-item {
  display: block;
  overflow: hidden;
  position: relative;
}

.div-blog-item > a {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 20px;
  opacity: 1;
}

.list-title,
.list-p {
  display: block;
  overflow: hidden;
  right: 5px;
}

.list-title {
  font-family: Oranienbaum, serif;
  color: #4400CC;
  text-align: right;
  padding: 10px 0px;
  font-size: 2rem;
}

.list-p {
  position: absolute;
  opacity: 0;
  margin-bottom: 30px;
  bottom: 0;
  color: #ffffff;
  padding: 1rem 1.5rem;
  transition: all 0.3s linear 0.3s;
}

a .list-title {
  color: #1e87f0;
}

.div-blog-item > a:hover .list-p {
  opacity: 1;
  transition: all 0.3s linear 0.3s;
}

.left-column-list-title {
  padding-right: 15px;
  border-right: 5px solid #0082CC;
  display: block;
  width: 100%;
}

.uk-comment-body {
  text-align: justify;
}

.blog-top-list {
  display: block;
  overflow: hidden;
  border-top: 1px solid #e6e6e6;
}

.blog-top-list a {
  display: block;
  overflow: hidden;
}

.list_intro_img {
  padding: 4px;
  border: 1px solid #e6e6e6;
}

section.footer {
  display: block;
  background: #f2f2f2;
  overflow: hidden;
  padding-top: 20px;
  border-top: 1px solid #e6e6e6;
  margin-top: 30px;
  color: #ffffff;
}

.footer-title {
  padding: 0;
  text-align: center;
}

.footer-menu li {
  list-style: none;
  text-align: center;
  margin-left: 0;
}

.footer-menu li a {
  display: block;
  width: auto;
  padding: 5px 10px;
  color: #00a3ff;
}

.footer-menu li.active a, .footer-menu li:hover a {
  background-color: #ffffff;
  color: #00a3ff;
}

@media (max-width: 575.98px) {
  .footer-menu li {
    margin-left: 0;
    text-align: center;
  }
  .footer-contacts {
    text-align: center;
  }
}

.copyright {
  text-align: center;
  background: #e6e6e6;
  color: #212529;
  padding: 15px 0;
  margin-top: 20px;
  border-top: 1px solid #d9d9d9;
}

.footer ul {
  padding-left: 0;
  margin-bottom: 30px;
}

.footer-title {
  color: #0082CC;
  border-bottom: 5px solid #0082CC;
  padding-bottom: 15px;
}

.footer-contacts > div {
  display: table;
  margin: auto;
  color: #0082CC;
  padding: 0 30px;
  text-align: left;
}

.footer-contacts > div h3, .footer-contacts > div h4 {
  color: #0082CC;
}

.footer-contacts-details {
  list-style: none;
}

ul.uk-pagination {
  display: inline-flex;
  margin-bottom: 0;
}

.contacts-letter.textarea-div > label {
  margin-bottom: 15px;
  font-weight: bold;
}

.contacts-details {
  list-style: none;
  display: table;
  margin: auto;
}

.contacts-details li {
  margin: 10px 0;
}

.uk-comment-avatar {
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  padding: 4px;
}

.wave-slide-bottom-svg {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.wave-slide-bottom-svg svg {
  color: #ffffff;
  transform: rotate(180deg);
  height: 15px;
}
