@import "global";

.share42init {
    display: flex;
    justify-content: center;
    margin: 40px 0 30px; }

.share42-counter {
    background-image: none !important;
    &:before {
        background-image: none !important; } }

.article-header {
    display: block;
    position: relative;
    overflow: hidden; }

.article-title {
    margin-bottom: 10px;
    text-align: right;
    color: darken($glColor_3,10%);
    padding: 10px 30px;
    margin-top: 30px;
    font-size: 2.5rem; }

@media (max-width: 959px) {
    .article-title {
        font-size: $glFz * 1.5; } }

.intro-image {
    margin: 30px 0; }

#cboxOverlay {
    background: #ffffff url(/images/background_8.jpg) center top; }

.img-in-text {
    display: flex;
    justify-content: center;
    padding: 0.25rem;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
    > a {
        display: flex;
        width: 100%;
        justify-content: center; } }

@media (max-width: 639px) {
    .img-in-text.right,
    .img-in-text.left {
        clear: both;
        margin: 20px auto 0; } }

@media (min-width: 640px) {
    .img-in-text.right {
        float: right;
        margin: 10px 0 10px 20px; }

    .img-in-text.left {
        float: left;
        margin: 10px 20px 10px 0; } }

.img-in-text.center {
    clear: both;
    margin: 20px auto; }

.author-image {
    display: block;
    width: 10%;
    float: left; }

.autor-id,
.author-name {
    text-align: right;
    font-weight: bold;
    padding-right: 30px; }

.author {
    margin-top: 30px;
    border-top: 1px solid #00cf59;
    display: flex;
    overflow: hidden;
    padding: 20px 0;
    justify-content: space-around;
    align-items: center; }

.author-image > img {
    border-radius: 50%;
    border: 1px solid #cccccc;
    padding: 4px; }

.author td {
    color: #4d5961;
    font-size: 0.9rem; }

.count-span {
    margin-left: 15px;
    > span {
        margin-right: 5px !important; } }

.article_intro_photo {
    padding: 4px;
    border: 1px solid darken($glBg, 10%); }

@media (max-width: 959px) {
    .article_intro_photo {
        display: block;
        overflow: hidden;
        width: 100%;
        clear: both;
        margin: 20px 0; } }

@media (min-width: 960px) {
    .article_intro_photo {
        display: block;
        overflow: hidden;
        width: 50%;
        float: right;
        margin: 20px 0 20px 20px; } }

.photo-gallery {
    display: block;
    overflow: hidden;
    .photo-gallery-image {
        width: 33.33%;
        height: auto;
        padding: 4px;
        border: 1px solid darken($glBg, 10%); } }

.article_author {
    display: block;
    overflow: hidden;
    border-top: 1px solid darken($glBg, 10%);
    border-bottom: 1px solid darken($glBg, 10%);
    padding: 20px 0;
    margin: 20px 0;
    clear: both;
    img {
        float: left;
        margin: 0 30px;
        padding: 4px;
        border: 1px solid darken($glBg, 10%);
        border-radius: 50%; } }

.exskursion-parameters {
    display: block;
    overflow: hidden;
    font-size: 1.2rem;
    padding: .2rem 0;
    & label {
        display: block;
        overflow: hidden;
        float: left;
        width: calc(50% - 20px);
        margin-right: 30px;
        text-align: right;
        font-weight: 400; }
    > div {
        float: left;
        width: calc(50% - 10px); } }

.top_line {
    border-top: 1px solid darken($glBg, 10%);
    padding-top: 1.5rem;
 }    // margin-bottom: 20px

.bottom_line {
    border-bottom: 1px solid darken($glBg, 10%);
    padding-bottom: 1.5rem; }

.pagination {
    display: block;
    overflow: hidden;
    clear: both;
    ul {
        display: table;
        margin: auto;
        list-style: none;
        li {
            display: inline-block;
            padding: 3px 10px;
            border: 1px solid darken($glBg, 10%);
            border-radius: 3px; } } }

.share42init {
    display: block;
    position: absolute;
    left: 20px; }
