@import "global";

.div-blog-item {
    display: block;
    overflow: hidden;
    position: relative; }

.div-blog-item>a {
    position: relative;
    display: block;
    overflow: hidden;
    margin-bottom: 20px;
    opacity: 1; }

.list-title,
.list-p {
    display: block;
    overflow: hidden;
    right: 5px; }

.list-title {
    font-family: $glHf;
    color: $glColor_1;
    text-align: right;
    padding: 10px 0px;
    font-size: 2rem; }

.list-p {
    position: absolute;
    opacity: 0;
    margin-bottom: 30px;
    bottom: 0;
    color: #ffffff;
    padding: 1rem 1.5rem;
    transition: all 0.3s linear 0.3s; }

a .list-title {
    color: #1e87f0; }

.div-blog-item>a:hover .list-p {
    opacity: 1;
    transition: all 0.3s linear 0.3s; }

.left-column-list-title {
    padding-right: 15px;
    border-right: 5px solid $glColor_2;
    display: block;
    width: 100%; }

.uk-comment-body {
    text-align: justify; }

.blog-top-list {
    display: block;
    overflow: hidden;
    border-top: 1px solid darken($glBg, 10%);
    a {
        display: block;
        overflow: hidden; } }

.list_intro_img {
    padding: 4px;
    border: 1px solid darken($glBg, 10%); }
