@import "global";

section.footer {
    display: block;
    background: darken($glBg,5%);
    overflow: hidden;
    padding-top: 20px;
    border-top: 1px solid darken($glBg,10%);
    margin-top: 30px;
    color: #ffffff; }

.footer {
    &-title {
        padding: 0;
        text-align: center; }
    &-menu {
        li {
            list-style: none;
            text-align: center;
            margin-left: 0;
            a {
                display: block;
                width: auto;
                padding: 5px 10px;
                color: lighten($glColor_2,10%); }
            &.active,
            &:hover {
                a {
                    background-color: $glBg;
                    color: lighten($glColor_2,10%); } } } } }

@media (max-width: 575.98px) {
    .footer {
        &-menu {
            li {
                margin-left: 0;
                text-align: center; } }
        &-contacts {
            text-align: center; } } }

.copyright {
    text-align: center;
    background: darken($glBg,10%);
    color: $glFc;
    padding: 15px 0;
    margin-top: 20px;
    border-top: 1px solid darken($glBg,15%); }

.footer ul {
    padding-left: 0;
    margin-bottom: 30px; }

.footer-title {
    color: $glColor_2;
    border-bottom: 5px solid $glColor_2;
    padding-bottom: 15px; }

.footer-contacts > div {
    display: table;
    margin: auto;
    color: $glColor_2;
    padding: 0 30px;
    text-align: left;
    h3, h4 {
        color: $glColor_2; } }

.footer-contacts-details {
    list-style: none; }
