@import "global";

.contacts-letter.textarea-div > label {
    margin-bottom: 15px;
    font-weight: bold; }

.contacts-details {
    list-style: none;
    display: table;
    margin: auto;
    li {
        margin: 10px 0; } }

.uk-comment-avatar {
    border: 1px solid darken($glBg, 10%);
    border-radius: 50%;
    padding: 4px; }
