@import "global";

.menu-block {
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center; }

.mainmenu {
    display: flex;
    padding-left: 0;
    justify-content: space-around;
    margin: 0;
    li {
        display: inline-block;
        margin: 0;
        list-style: none;
        box-sizing: initial;
        a {
            display: block;
            overflow: hidden;
            font-family: $glHf;
            color: darken($glBg, 30%);
            text-align: center;
            float: left;
            transition: all 0.3s;
            margin: 0 2px;
            padding: 0 7px 5px;
            border-bottom: 3px solid rgba(255,255,255,0);
            height: 27px;
            box-sizing: initial; }
        ul {
            display: none; }
        &.selected a, &.active a, a:hover {
            color: $glColor_2;
            border-bottom: 3px solid $glColor_2;
            text-decoration: none; } } }

@media (min-width: 640px) {
    .mainmenu {
        li {
            a {
                font-size: 13px;
                padding: 0 3px 2px; } } } }

@media (min-width: 960px) {
    .mainmenu {
        li {
            a {
                font-size: 20px; } } } }

@media (min-width: 1200px) {
    .mainmenu {
        li {
            a {
                font-size: 25px; } } } }

ul.mainmenu li.active ul {
    display: block;
    height: 27px;
    position: absolute;
    top: 33px; }

ul.mainmenu li.active ul li {
    display: inline; }

ul.mainmenu li.active ul li a {
    border: 0;
    color: #C6C6C6;
    font-size: 18px;
    transition: all 1s;
    padding-top: 10px; }

ul.mainmenu li.active ul li.active a,
ul.mainmenu li.active ul li a:hover,
ul.mainmenu li.active ul li.selected a {
    color: #ffffff;
    background-color: #00A221; }

.logo {
    display: block;
    overflow: hidden; }

nav {
    position: relative; }

div.mod-languages ul {
    margin-right: 15px; }

input[id="menuOnOff"] {
    display: none; }

.uk-logo svg {
    height: 100%; }

.uk-navbar-item {
    height: auto; }

.header {
    background: $glBg; }

.top-icon-img {
    height: auto;
    color: darken($glBg, 30%);
    &:hover {
        color: $glColor_2; } }

@media (max-width: 959px) {
    .top-icon-img {
        width: 32px; } }

@media (min-width: 960px) {
    .top-icon-img {
        width: 40px; } }

.top-menu {
    padding: 10px 0; }

.menu-div {
    padding-top: 15px; }

@media (max-width: 639px) {
    .menu-div {
        padding-top: 0; } }

#menu_on_off, label[for="menu_on_off"] {
    display: none; }

@media (max-width: 639px) {
    label[for="menu_on_off"] {
        display: flex;
        width: 45px;
        height: 45px;
        border: 2px solid darken($glBg, 30%);
        border-radius: 5px;
        margin: auto;
        color: darken($glBg, 30%);
        box-shadow: 0px 0px 5px $glBg;
        cursor: pointer;
        & + ul {
            display: none;
            position: absolute;
            top: 70px;
            height: 0;
            padding: 10px;
            border: 1px solid darken($glBg, 15%);
            border-radius: 5px;
            opacity: 0;
            background: rgba($glBg, 0);
            transition: all 1s linear; }
        & > svg {
            width: 30px;
            height: 30px; } }
    .menu-div > input:checked + label[for="menu_on_off"] + ul {
        display: table;
        background: rgba($glBg, 1);
        height: auto;
        opacity: 1;
        transition: all 1s linear;
        z-index: 1000; }
    .mainmenu li {
        display: block;
        float: none;
        text-align: center;
        a {
            margin: 5px 2px;
            width: calc(100% - 8px);
            height: auto;
            padding: 0; } } }

