@import "global";

* {
    margin: 0;
    padding: 0; }

body {
    background: {
        image: url(/images/background_8.jpg);
        color: $glBg; }
    font: {
        size: $glFz;
        family: $glFf;
        weight: 300; }
    color: $glFc;
    line-height: $glLh; }

@media (max-width: 959px) {
    body {
        font-size: $glFz * 0.9; } }

blockquote {
    margin: 20px 0 20px 35px;
    position: relative;
    font-size: 0.9rem;
    font-style: inherit;
    padding: 10px 5px 10px 15px;
    border-left: 5px solid $glBdc;
    background-color: #f8f8f8;
    &:before {
        content: "\201d";
        display: block;
        position: absolute;
        left: -35px;
        color: $glBdc;
        font-size: 60px;
        line-height: 70%; } }

.uk-alert {
    padding: 25px 29px 25px 25px; }

.uk-alert-message {
    background: #edfbf6;
    color: lighten(#32d296,20%); }

// .uk-totop.uk-icon

a#toTop {
    display: flex;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: fixed;
    bottom: 15px;
    right: 15px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    font-size: 30px;
    background: linear-gradient(to top, darken($glColor_2,5%), lighten($glColor_2,5%));
    justify-content: center;
    z-index: 9999;
    line-height: 180%;
    &:hover {
        color: lighten($glColor_1,20%); } }

.mt30 {
    margin-top: 30px; }

.mt25 {
    margin-top: 25px; }

.mt20 {
    margin-top: 20px; }

.mt15 {
    margin-top: 15px; }

.mt10 {
    margin-top: 10px; }

.mt5 {
    margin-top: 5px; }

.mb30 {
    margin-bottom: 30px; }

.mb25 {
    margin-bottom: 25px; }

.mb20 {
    margin-bottom: 20px; }

.mb15 {
    margin-bottom: 15px; }

.mb10 {
    margin-bottom: 10px; }

.mb5 {
    margin-bottom: 5px; }

h1 {
    color: $glColor_2; }

.uk-section-default {
    background: inherit; }

.cck-clear {
    display: none !important; }

.uk-section.footer {
    padding-bottom: 0; }

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    padding: 10px;
    h3 {
        padding: .5rem .75rem;
        margin-bottom: 0;
        margin-top: 0;
        font-size: 1rem;
        color: inherit;
        background-color: #f7f7f7;
        border-bottom: 1px solid #ebebeb;
        border-top-left-radius: calc(.3rem - 1px);
        border-top-right-radius: calc(.3rem - 1px); }
    &-body {
        padding: .5rem .75rem;
        color: #212529; } }

.uk-flex {
    display: flex !important; }

div.cck-f100 {
    display: inherit;
    position: inherit;
    width: auto; }

.div-scroll-to-top {
    display: block;
    position: relative;
    width: 40px; }

em {
    color: $glFc; }

.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $glHf; }

p {
    text-align: justify; }

#system-message {
    display: block;
    overflow: hidden;
    max-width: 1200px;
    margin: auto; }
