@import "global";

.first-page-list-a {
    display: block;
    overflow: hidden;
    position: relative; }

.first-page-list-title {
    display: block;
    overflow: hidden;
    font-family: $glHf;
    margin-top: 10px;
    text-align: right;
    font-size: 1.7rem; }

a .first-page-list-title {
    color: #1e87f0; }

.slider-div {
    display: block;
    overflow: hidden;
 }    // height: 100vh

.wave-top-svg {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 30px;
    position: absolute;
    top: 0; }

.second-wave-svg.uk-svg {
    display: block;
    width: 100%;
    height: 30px;
    color: $glBg; }

.big-wave-svg-div {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0;
    transform: rotate(180deg); }

.big-wave-svg.uk-svg {
    display: block;
    overflow: hidden;
    width: 100%;
    color: #ffffff; }

@media (max-width: 639px) {
    .big-wave-svg.uk-svg {
        height: 50px; } }

@media (max-width: 959px) {
    .big-wave-svg.uk-svg {
        height: 75px; } }

@media (min-width: 960px) {
    .big-wave-svg.uk-svg {
        height: 100px; } }

.best-offers-a-link {
    display: table;
    overflow: hidden;
    color: $glBg;
    font-weight: 400;
    background: $glColor_2;
    border: 1px solid lighten($glColor_2, 10%);
    margin: 20px auto;
    border-radius: 23px;
    padding: 10px 30px;
    &:hover {
        color: darken($glBg, 15%);
        background: lighten($glColor_2, 10%); } }

.first-page-avatar {
    width: 100px;
    border-radius: 50%;
    float: left;
    margin-right: 30px;
    margin-bottom: 15px;
    border: 1px solid darken($glBg, 15%); }

.latest-contacts > a {
    font-size: .9rem;
    color: $glFc;
    &:hover {
        color: lighten($glFc, 20%);
        text-decoration: none; } }
