@import "global";

.wave-slide-bottom-svg {
    width: 100%;
    position: absolute;
    bottom: 0;
    svg {
        color: $glBg;
        transform: rotate(180deg);
        height: 15px; } }
