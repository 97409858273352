input {
    padding: 4px 5px; }

.edit-button-div {
    display: flex;
    justify-content: flex-end; }

.edit-button-div>a {
    border: 1px solid #cccccc; }

.control-group {
    display: flex;
    margin-bottom: 20px;
    align-items: stretch; }

.control-group .control-label {
    width: calc(50% - 20px);
    margin-right: 20px;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center; }

.control-group .control-label>label {
    margin: 0;
    display: table; }

.controls {
    display: block;
    overflow: hidden; }

.login .btn.btn-primary,
.remind .btn.btn-primary,
.reset .btn.btn-primary,
.logout .btn.btn-primary {
    display: table;
    margin: auto; }

ul.login {
    display: table;
    width: auto;
    padding: 0;
    margin: auto; }

ul.login li {
    list-style: none;
    margin: 0 10px 30px;
    float: left; }

.cck_page_form label {
    display: block;
    overflow: hidden;
    float: left;
    width: calc(50% - 22px);
    text-align: right;
    margin-right: 20px;
    margin-bottom: 0;
    padding: 0.4rem 0; }

.cck_page_form label+input,
.cck_page_form label+select,
.cck_page_form label+.field-calendar {
    width: 50%;
    margin-bottom: 20px; }

.field-calendar {
    height: 40px;
    float: left; }

.field-calendar .input-append > input {
    width: calc(100% - 38px);
    float: left; }

.field-calendar .input-append > button {
    display: block;
    overflow: hidden;
    padding: 0;
    margin: 0;
    width: 38px;
    height: 38px;
    float: right; }

.icon-calendar::after {
    content: "\2630";
    font-size: 20px; }

div[id*="chars-article_intro_"] {
    display: block;
    overflow: hidden;
    clear: both;
    margin-bottom: 20px;
    text-align: center; }

label[for*="art_fulltext"],
label[for*="art_metadesc"],
label[for="article_content_photo"],
label[for="article_photo_gallery"] {
    width: 100%;
    text-align: center;
    float: none;
    margin-bottom: 20px;
    margin-right: 0;
    font-size: 1.5rem; }

label[for="article_intro_image_title"] {
    display: inline;
    font-size: inherit; }

.tab-content {
    margin: 30px 0; }

.btn-toolbar button {
    margin: 0 10px; }

.logout {
    display: block;
    overflow: hidden;
    margin-bottom: 30px; }

.free-text-title {
    font-size: 2.5rem;
    display: block;
    overflow: hidden;
    margin-bottom: 30px; }

.free-text-title>label {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 8px 0; }

.collection-group-button {
    display: flex;
    overflow: hidden;
    justify-content: right; }

.icon-minus,
.icon-plus,
.icon-circle {
    display: block;
    width: 38px;
    height: 38px;
    float: left;
    margin-right: 10px;
    margin-bottom: 5px; }

.icon-minus::after,
.icon-plus::after,
.icon-circle::after {
    content: "";
    font-size: 30px;
    font-weight: bold;
    border: 1px solid #cccccc;
    display: flex;
    width: 38px;
    height: 38px;
    border-radius: 5px;
    justify-content: center;
    align-items: center; }

.icon-minus::after {
    content: "-"; }

.icon-plus::after {
    content: "+"; }

.icon-circle::after {
    content: "\00F7"; }

.icon-minus,
.icon-plus {
    cursor: pointer; }

.icon-circle {
    cursor: all-scroll; }

.adminformlist.ui-sortable>div {
    display: block;
    overflow: hidden;
    width: calc(50% - 10px);
    float: left;
    border-bottom: 1px solid #cccccc;
    padding: 15px 0; }

.adminformlist.ui-sortable>div:nth-child(2n) {
    float: right; }

.cck_label_upload_image,
.cck_form_upload_image {
    display: block;
    overflow: hidden;
    margin-bottom: 10px; }

.cck_label_upload_image>label {
    width: 100%;
    text-align: left; }

.photo-gallery-div {
    .gallery-image {
        width: 25%; } }

.formError .formErrorContent {
    background: #ffffff;
    color: #212529;
    font-size: 13px;
    border: 1px solid #cccccc;
    box-shadow: 0 0 3px #cccccc; }

.formError .formErrorArrow {
    border-color: #cccccc transparent transparent transparent; }

#user_password_update>input {
    display: none; }

#user_password_update>label {
    text-align: center;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 5px 10px;
    width: calc(50% - 30px);
    margin: 0; }

#user_password_update>label:nth-child(4n) {
    float: right; }

#user_password_update>input:checked+label {
    background: #dddddd; }

.pass-change-div>div,
.pass-change-div>fieldset {
    margin-bottom: 20px; }

div[id^="chars"] {
    display: table;
    margin: 0 auto 20px; }

.user-add-edit-form>.hasTooltip {
    display: none; }

.user-add-edit-form>.cck_upload_image {
    display: table;
    margin: auto; }

ul.nav-tabs {
    & li {
        list-style: none;
        margin-left: 0; } }

fieldset {
    border: none;
    &[id^="moneybox_heading"] {
        border-bottom: 1px solid $glColor_2; } }

.nav-tabs {
    display: flex;
    justify-content: flex-start;
    border-bottom: {
        width: 1px;
        style: solid;
        color: rgba(#ccc, 0.8); }
    padding-left: 0; }

.nav-tabs > li {
    display: inline-table;
    padding: 7px 15px;
    border-left: {
        width: 1px;
        style: solid;
        color: rgba(#ccc, 0); }
    border-top: {
        width: 1px;
        style: solid;
        color: rgba(#ccc, 0); }
    border-right: {
        width: 1px;
        style: solid;
        color: rgba(#ccc, 0); }
    &.active {
        border-left: {
            width: 1px;
            style: solid;
            color: rgba(#ccc, 0.8); }
        border-top: {
            width: 1px;
            style: solid;
            color: rgba(#ccc, 0.8); }
        border-right: {
            width: 1px;
            style: solid;
            color: rgba(#ccc, 0.8); } } }

.tab-content > div {
    display: none;
    &.active {
        display: block;
        overflow: hidden; } }

.cck_label.cck_label_password {
    display: inline-flex;
    width: calc(50% - 20px) !important;
    justify-content: flex-end;
    margin-right: 20px;
    & > label {
        width: auto;
        padding: 0;
        margin: .5rem 0 0; } }

.cck_forms.cck_site.cck_password {
    overflow: hidden; }

.cck_form.cck_form_password {
    width: 50% !important; }

.btn-toolbar {
    display: flex;
    justify-content: space-around; }

fieldset[id="ekskursii_place"] {
    & input {
        display: none;
        &:checked + label {
            background: darken($glBg, 10%);
            border: 1px solid darken($glBg, 20%); } }
    & label {
        text-align: center;
        border: 1px solid darken($glBg, 10%);
        margin-bottom: 15px;
        cursor: pointer; } }

.uk-accordion-title {
    font-weight: 400;
    background: $glColor_2;
    border: 1px solid lighten($glColor_2, 10%);
    border-radius: 25px;
    color: $glBg;
    padding: 10px 30px;
    &:hover,
    &:focus {
        color: darken($glBg, 10%); } }

.uk-accordion-title::after {
    background-image: none;
    content: "+"; }

.uk-open > .uk-accordion-title::after {
    background-image: none;
    content: "–"; }

.search-exkursion-module > label {
    display: flex;
    overflow: hidden;
    width: 30%;
    height: 40px;
    float: left;
    margin-right: 30px;
    margin-bottom: 15px;
    justify-content: flex-end;
    align-items: center;
    font-weight: 400; }

.search-exkursion-module > select,
.search-exkursion-module > fieldset {
    display: block;
    overflow: hidden;
    width: calc(70% - 30px);
    margin-bottom: 15px; }

.search-exkursion-module > fieldset {
    margin-top: 0;
    & > label {
        display: inline-table;
        overflow: hidden;
        margin: 3px 5px;
        background: $glBg;
        padding: 5px 20px; } }
